@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
.features-section {
  background-color: #000; /* Replace with the actual background color */
  color: #fff;
  text-align: center;
  margin-top: 0;
  width: auto;
}

.section-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-two {
  height: auto;
  flex-shrink: 0;
  background: linear-gradient(180deg, #2a2a2a 0%, #000 12.91%);
}

.painter-img {
  height: 710px;
  width: auto;
}
.third {
  display: flex;
  position: relative;
  top: 2em;
}

.fourth {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 50px;
  top: 0.5em;
}

.step-img {
  position: relative;
  width: auto;
  height: 450px;
  margin-left: auto;
  margin-right: 5rem;
  top: -1.9em;
}

.section-two .line-img img {
  width: 930px;
  margin-left: 70px;
  height: 200px;
  position: relative;
  top: 1em;
}

.section-two-text3 {
  text-align: start;
  margin-left: 8rem;
}

.section-two-text4 {
  text-align: end;
  margin-right: 8rem;
}

.section-two .line-two-img img {
  width: 920px;
  margin-left: 70px;
  height: 200px;
  position: relative;
  top: 1.9em;
}

.section-two .ladder-img {
  display: flex;

  justify-content: flex-start; /* Aligns the image to the left */
  margin-left: 8rem; /* Adjust the margin as needed */
}

.section-two .ladder-img img {
  height: 500px;
  width: auto;
}

.welcome-text {
  text-align: left;
  margin-left: 8rem;
  position: relative;
  top: -6em;
}

.fixer-img {
  height: 470px;
  width: auto;
  margin-left: 8rem;
}

.text-one {
  font-size: 50px;
  margin-top: 0;
  font-family: "Poppins";
  position: relative;
  top: -0.7em;
}

.text-two {
  color: #14df8a;
  font-size: 50px;
  font-family: "Poppins";
  top: -1em;
  position: relative;
}

.text-four {
  color: #14df8a;
  font-size: 42px;
  font-family: "Poppins";

  position: relative;
}

.text-three {
  font-weight: 500;
  font-family: "Poppins";
}

.text-five {
  font-size: 42px;
  margin-top: 0;
  top: -0.4em;
  font-family: "Poppins";
  position: relative;
}

.section-two-text {
  text-align: right;
  margin-right: 8rem;
}

.ladder-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 1.2em;
}

@media (min-width: 1024px) {
  .text-one-mobile {
    display: none;
  }
  .text-two-mobile {
    display: none;
  }

  .painter-mobile-img {
    display: none;
  }

  .ladder-mobile-img {
    display: none;
  }

  .section-two-mobile-text {
    display: none;
  }

  .section-two-text3-mobile {
    display: none;
  }

  .section-one-mobile {
    display: none;
  }

  .section-two-mobile {
    display: none;
  }
}

/* Media query for tablets and down to larger phones (devices with width less than 768px) */
@media (max-width: 768px) {
  .features-section {
    background-color: #000; /* Replace with the actual background color */
    color: #fff;
    text-align: start;
    margin-top: 0;
    height: 2300px;
    width: 100%;
    position: relative;
  }

  .section-one-mobile {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  .section-two-mobile {
    position: relative;
    top: -6em;
  }

  .line-1-mobile {
    position: relative;
    bottom: 5em;
    right: 4em;
  }

  .section-one {
    display: none;
  }

  .welcome-text {
    text-align: left;
    margin-left: 1rem;
    position: relative;
    top: 2em;
  }
  .text-one-mobile {
    font-size: 35px;
    padding-top: 20px;
    font-family: "Poppins";
    position: relative;
    margin-left: 1rem;
  }
  .text-two {
    display: none;
  }

  .text-one {
    display: none;
  }
  .text-two-mobile {
    color: #14df8a;
    width: auto;
    font-size: 35px;
    margin-left: 1rem;
    font-family: "Poppins";
    position: relative;
  }
  .painter-mobile-img {
    height: 490px;
    width: 200px;
    position: relative;
  }
  .painter-img {
    display: none;
  }

  .section-two .line-img img {
    display: none;
  }

  .text-three {
    font-weight: 500;
    font-size: 14px;
    font-family: "Poppins";
  }

  .section-two {
    display: none;
  }

  .ladder-img img {
    display: none;
  }

  .ladder-mobile-img img {
    margin-left: 1rem;
    height: 490px;
    margin-right: 0px;
    padding-right: 0px;
  }

  .section-two-text {
    display: none;
    text-align: right;
    margin-right: 1rem;
  }
  .text-four {
    color: #14df8a;
    font-size: 20px;
    font-family: "Poppins";
  }

  .text-five {
    font-size: 20px;
    margin-top: 0;
    font-family: "Poppins";
  }

  .ladder-section {
    display: flex;
  }

  .section-two-mobile-text {
    justify-self: start;
    position: relative;
    top: -4em;
    margin-right: 1em;
  }

  .third-mobile {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    position: relative;
    top: -20em;
  }

  .step-img-mobile {
    height: 260px;
    width: 240px;
    position: relative;
    top: 9.5em;
    right: 2em;
    margin-right: 1rem;
    margin-bottom: 3rem;
  }

  .section-two .line-two-img img {
    display: none;
    width: 920px;
    margin-left: 70px;
    height: 200px;
    position: relative;
    top: 1.9em;
  }
  .section-two-text3-mobile {
    text-align: start;
    margin-left: 1rem;
    margin-top: 2rem;
    width: 100%;
    position: relative;
  }

  .section-two-text3 {
    display: none;
  }

  .step-img {
    display: none;
    position: relative;
    width: auto;
    height: 450px;
    margin-left: auto;
    margin-right: 5rem;
    top: -1.9em;
  }
  .fixer-img-mobile {
    height: 250px;
    position: relative;
    top: -17em;

    margin-bottom: 50px;
  }

  .line-3-mobile {
    position: relative;
    top: -16em;
    right: 13em;
    width: 70px;
    height: 200px;
  }

  .line-2-mobile {
    position: relative;
    left: 10em;
    top: -10em;
    height: 400px;
    width: 120px;
  }

  .fourth-mobile {
    display: flex;
    margin-right: 2rem;
    flex-direction: column;
    justify-content: end;
    align-items: flex-end;
  }

  .section-two-text4-mobile {
    position: relative;
    top: -20em;
  }

  .fixer-img {
    display: none;
  }
}
