.header {
  background-color: #0e0e0e;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8rem 0 8rem;
}

.header-logo img {
  height: auto;
  width: 60px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.header-links {
  display: flex;
  align-items: center;
  margin-right: 50px; /* Add margin to separate it from .header-cta */
}

.header-links button {
  color: #fff;
  text-decoration: none;
  margin: 0 15px;
  background-color: #0e0e0e;
  border: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: larger;
}

.header-cta {
  display: flex;
  align-items: center;
}

.header-group {
  display: flex;
  align-items: center;
}

.cta-button {
  background-color: #14df8a;
  color: #060606;
  border: 2px #14df8a;
  padding: 10px 25px;
  margin-bottom: 25px;
  margin-top: 30px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.1s ease;
  font-family: "Poppins", sans-serif;
  font-size: large;
  align-self: center;
}

.cta-button2 {
  background-color: #ffffff;
  color: #060606;
  border: 2px #ffffff;
  padding: 10px 25px;
  margin-bottom: 25px;
  margin-top: 50px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.1s ease;
  font-family: "Poppins", sans-serif;
  font-size: large;
  align-self: center;
}

.cta-button:hover,
.cta-button:focus {
  border-color: #ffffff;
  color: #000000;
  outline: none;
  background-color: #ffffff;
}

.cta-button2:hover,
.cta-button2:focus {
  border-color: #00d084;
  color: #000000;
  outline: none;
  background-color: #00d084;
}

@media (max-width: 768px) {
  .header {
    flex-direction: row;
    padding: 0px 0px 0px 15px;
  }
  .mobile-buuton2 {
    position: relative;
  }

  .header-logo img {
    width: 50px;
    height: 50px;
  }
  .header-nav {
    margin-bottom: 10px;
  }

  .header-cta {
    display: none;
    padding: 0px;
    margin: 0px;
  }

  .header-links {
    margin: 0px;
    padding: 0px;
    align-self: flex-end;
    display: flex;
    font-size: 15px;
  }

  .header-nav button {
    margin: 5px 0; /* Stack the buttons vertically */
  }

  .cta-button {
    background-color: #14df8a;
    color: #060606;
    border: 2px #14df8a;
    margin-top: 30px;
    border-radius: 10px;
    font-weight: 550;
    cursor: pointer;
    width: 180px;
    height: 50px;
    transition: background-color 0.1s ease;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    align-self: center;
  }

  .cta-button2 {
    background-color: #ffffff;
    color: #060606;
    border: 2px #ffffff;
    margin-top: 30px;
    border-radius: 10px;
    font-weight: 550;
    cursor: pointer;
    width: 180px;
    height: 50px;
    transition: background-color 0.1s ease;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    align-self: center;
  }
}
