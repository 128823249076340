@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
.footer {
  background-color: #000;
  color: #fff;
  font-family: "Arial", sans-serif;
}

.footer-content {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 8rem;
}

.footer-logo {
  width: 65px;
  height: auto;
}

.footer-social {
  display: flex;
  margin-left: 20px;
}

.footer-social img {
  margin-left: 15px;
}

.contacts p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.footer-bar p {
  margin: 20px;
  margin-left: 8rem;
  font-size: 0.8em;
  padding-right: 80px;
  text-align: start;
  font-family: "Poppins", sans-serif;
}

@media (min-width: 1024px) {
  .footer-content-mobile {
    display: none;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-content {
    display: none;
  }
  .footer-content-mobile {
    flex-direction: column;
    text-align: center;
  }

  .footer-links {
    margin-top: 20px;
    flex-direction: column;
  }

  .footer-social-mobile {
    margin-top: 20px;
  }

  .contacts-mobile {
    text-align: center;
  }

  .footer-bar p {
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
    text-align: center;
    font-size: 0.7em;
    font-family: "Poppins";
  }
}
