@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
.waitlist-section {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 100px;
  padding-top: 100px;
  background: linear-gradient(180deg, #14df8a -34.95%, #000 57.16%);
}

.waitlist-content h2 {
  color: #14df8a; /* Replace with the actual color for the heading */
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  margin-left: 8rem;
}

.form-submit-message {
  display: inline-flex;
  align-items: end;
}

.form-message {
  margin-left: 15px;
  padding-bottom: 5px;
}

.waitlist-content p {
  color: #fff; /* Replace with the actual color for the paragraph */
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
  margin-right: 0;
}

.waitlist-form {
  margin-left: 8rem;
  display: flex;
  flex-direction: column;
}
.name {
  height: 35px;
  width: 600px; /* Adjust the width as necessary */
}
.email {
  height: 35px;
  width: 600px; /* Adjust the width as necessary */
}
.country {
  height: 35px;
}

.city {
  height: 35px;
}
.location-fields {
  display: flex;
  gap: 10px;
}

.location-fields input {
  flex: 1; /* allows both fields to share the available space equally */
  background: #fff; /* Field background color */
  border: 2px solid #ffffff;
  border-radius: 10px; /* Rounded corners */
  padding: 10px;
  color: #000; /* Text color inside inputs */
  font-family: "Poppins", sans-serif;
}

/* Ensures focus effect on inputs */
.location-fields input:focus {
  outline: none;
}

.waitlist-form input[type="text"],
.waitlist-form input[type="email"] {
  padding: 10px;
  margin-bottom: 15px;
  border: 2px #dfdede; /* Replace with the actual border color */
  border-radius: 5px; /* Adjust the border-radius as necessary */
  font-family: "Poppins", sans-serif;
}

/* .waitlist-form button {
  background-color: #14df8a; 
  color: #000;
  border: none;
  padding: 10px;
  border-radius: 5px; 
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  transition: background-color 0.5s ease;
  margin-top: 20px;
  font-size: 1rem;
  width: 200px;
} */

/* .waitlist-form button:hover {
  background-color: #fff;
} */

.waitlist-illustration img {
  max-width: 50%;
  height: auto; /* Maintain aspect ratio */
}

@media (min-width: 1024px) {
  .waitlist-content-mobile {
    display: none;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .waitlist-content {
    display: none;
  }

  .waitlist-section {
    display: block;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 50px;
    padding-top: 50px;
    background: linear-gradient(180deg, #14df8a -34.95%, #000 57.16%);
  }

  .waitlist-content-mobile h2 {
    color: #14df8a; /* Replace with the actual color for the heading */
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    font-size: 1.8rem;
    margin-left: 1rem;
    text-align: center;
  }

  .waitlist-form-mobile {
    margin-left: 1rem;
    margin-right: 1rem;
    gap: 5px;
    display: flex;
    flex-direction: column;
  }

  .waitlist-form-mobile input[type="text"],
  .waitlist-form-mobile input[type="email"] {
    padding: 10px;
    margin-bottom: 15px;
    border: 2px #dfdede; /* Replace with the actual border color */
    border-radius: 5px; /* Adjust the border-radius as necessary */
    font-family: "Poppins", sans-serif;
  }

  .form-submit-message-mobile {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .form-message-mobile {
    color: #fff;
    font-family: "Poppins";
  }
}
