.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Basic reset and global styles */
body,
h1,
h2,
h3,
p,
ul {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

body {
  background-color: #f4f4f4;
  color: #333;
  line-height: 1.6;
}

/* Utility classes for padding and margin */
.padding {
  padding: 20px;
}

.margin {
  margin: 20px;
}

/* Container for centering content in the middle of the page */
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

/* Navigation styles */
nav {
  background-color: #007bff;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

nav ul {
  list-style: none;
  display: flex;
}

nav ul li {
  margin: 0 15px;
}

nav a {
  color: white;
  text-decoration: none;
  font-weight: 500;
}

/* Hero section styles */
.hero {
  background-color: #007bff;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.hero h1 {
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

/* Feature card styles */
.feature-card {
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
}

.feature-card h2 {
  color: #007bff;
}

.feature-card p {
  margin-top: 10px;
}

/* Footer styles */
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-text {
  margin: 0;
  padding: 10px;
  font-size: 0.8em;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 10px 0;
  }

  .hero {
    padding: 20px;
  }

  .feature-card {
    margin: 10px;
  }
}
